@font-face {
    font-family: Inter;
    src: url(./fonts/Inter/Inter-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: Inter;
    src: url(./fonts/Inter/Inter-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: Inter;
    src: url(./fonts/Inter/Inter-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: Inter;
    src: url(./fonts/Inter/Inter-Bold.ttf);
    font-weight: 700;
}
